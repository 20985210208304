@keyframes flipInX {
  from {
    transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    opacity: 0;
  }

  to {
    transform: perspective(400px) rotate3d(0);
    opacity: 1;
  }
}

.flipper {
  display: inline-block;
  backface-visibility: hidden;
  animation-name: flipInX;
  animation-duration: 0.6s;
  animation-fill-mode: both;
}
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700');

html {
    /* width: 100vw; */
    max-width: 100%;
    overflow: auto;
    font-family: 'Roboto', serif;
}

body {
    margin: 0 !important;
    padding: 0 !important;
    /* background-color: #faf9f8 !important; */
}

.mr50 {
    margin-right: 50px;
}

.img {
    height: 70px;
}

.logoimg2 {
    max-height: 30px;
    max-width: 50px;
    object-fit: scale-down;
}

.product-image {
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
}

.iframe {
    height: 100%;
    width: 100%;
}

.stickyy {
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    z-index: 1;
}